<template>
    <div class="bid_main_wrap">
        <div class="bid_content_wrap">
            <div class="bid_title_wrap">
                <h1 class="title">{{ $t('licenseBuy') }}</h1>
            </div>

            <div class="content_wrap">
                <!--썸네일-->
                <div class="img_wrap">
                    <div class="b_img">
                        <img :src="returnThumbnail(mainImg)"/>
                    </div>
                    <swiper class="small_img_wrap" ref="swiper" :options="swiperOption" v-if="thumnailStatus"
                            :slidesPerView="'auto'" @click="onClick"
                            @slideChange="slideChange">
                        <swiper-slide class="s_img">
                            <img :src="returnSmallThumbnail(orgItemImg)"/>
                        </swiper-slide>
                        <swiper-slide class="s_img"
                                      v-for="(data,index) in thumnailImgs"
                                      :key="`detail${index}`"
                                      v-if="data.org_name != 'no_image.png'"
                        >
                            <img :src="returnSmallThumbnail(data.org_url)"/>
                        </swiper-slide>
                    </swiper>
                </div>
                <div class="inform_wrap">
                    <!--회원 간이 프로필-->
                    <div class="inform_header">
                        <div class="inform_h_header">
                            <ul class="thumb_img">
                                <li>
                                    <el-popover
                                            trigger="click"
                                            @show="getMemberData(data,'im_originator')"
                                            :open-delay="500"
                                            :offset="0"
                                            :append-to-body="false"
                                            :popper-options="{
                                            boundariesElement: 'body',
                                            gpuAcceleration: true,
                                            positionFixed: true,
                                            preventOverflow: true,}"
                                            popper-class="main_popover no_profile_popover"
                                    >
                                        <img slot="reference" class="card_profile"
                                             :src="returnItemProfile(itemData,'originator')">
                                        <div class="popover_wrap" v-if="checkMemberData()"
                                             @click="moveMember(`creator/${itemData.im_originator}`)">
                                            <div class="popover_top">
                                                <div class="popover_creator">
                                                    {{ $t('k_creator') }}
                                                </div>
                                                <div class="popover_nickname">
                                                    <img class="nickname_img" :src="returnMemberImg()">
                                                    <div class="popover_text_list">
                                                        <div class="popover_nickname_list">
                                                            <div class="nick_text">{{ memberData.mb_nick }}</div>
                                                            <div class="pop_curator" v-if="memberData.g_idx=== 2">
                                                                <span>Curator</span></div>
                                                            <div class="pop_curator second_color"
                                                                 v-if="memberData.mb_premium===1">
                                                                <span>Special</span></div>
                                                        </div>
                                                        <div class="popover_nickname2">
                                                            <img class="pn_img"
                                                                 src="@/assets/image/main/heart1.png">
                                                            <div>{{ memberData.likeCnt }}</div>
                                                            <div>ITEM</div>
                                                            <div>{{ memberData.itemCnt }}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="popover_bottom">
                                                <div>
                                                    {{ memberData.mb_introduce }}
                                                </div>
                                            </div>
                                        </div>
                                        <div class="popover_wrap" v-else>
                                            <div class="popover_top no_profile">
                                                <div class="popover_creator no_profile_creator">
                                                    {{ $t('k_creator') }}
                                                </div>
                                                <div class="popover_nickname no_profile_nickname">
                                                    <img class="nickname_img no_profile_img"
                                                         src="@/assets/image/main/no_profile.png">
                                                    <div class="popover_text_list no_profile_text">
                                                        <div class="popover_nickname_list">
                                                            <div class="nick_text no_profile_text">{{
                                                                    $t('notInfo')
                                                                }}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="popover_bottom no_profile_bottom">
                                                <div class="pop_pover_div no_profile_div">
                                                    {{ $t('notProvider') }}
                                                </div>
                                            </div>
                                        </div>
                                    </el-popover>
                                </li>

                                <li>
                                    <el-popover
                                            :open-delay="500"
                                            :offset="0"
                                            @show="getMemberData(itemData,'provider')"
                                            :append-to-body="false"
                                            :popper-options="{
                          boundariesElement: 'body',
                          gpuAcceleration: true,
                          positionFixed: true,
                          preventOverflow: true,}"
                                            trigger="click"
                                            popper-class="bid_popover">
                                        <img slot="reference" class="card_profile"
                                             :src="returnItemProfile(itemData,'provider')">
                                        <div class="popover_wrap" v-if="checkMemberData()"
                                             @click="moveMember(`creator/${itemData.provider}`)">
                                            <div class="popover_top">
                                                <div class="popover_creator">
                                                    {{ $t('owner') }}
                                                </div>
                                                <div class="popover_nickname">
                                                    <img class="nickname_img" :src="returnMemberImg()">
                                                    <div class="popover_text_list">
                                                        <div class="popover_nickname_list">
                                                            <div class="nick_text">{{ memberData.mb_nick }}</div>
                                                            <div class="pop_curator" v-if="memberData.g_idx=== 2">
                                                                <span>Curator</span></div>
                                                            <div class="pop_curator second_color"
                                                                 v-if="memberData.mb_premium===1">
                                                                <span>Special</span></div>
                                                        </div>
                                                        <div class="popover_nickname2">
                                                            <img class="pn_img"
                                                                 src="@/assets/image/main/heart1.png">
                                                            <div>{{ memberData.likeCnt }}</div>
                                                            <div>ITEM</div>
                                                            <div>{{ memberData.itemCnt }}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="popover_bottom">
                                                <div>
                                                    {{ memberData.mb_introduce }}
                                                </div>
                                            </div>
                                        </div>
                                    </el-popover>
                                </li>
                                <li>
                                    <el-popover
                                            :open-delay="500"
                                            :offset="0"
                                            @show="getMemberData(itemData,'ibc_curator')"
                                            :append-to-body="false"
                                            :popper-options="{
                          boundariesElement: 'body',
                          gpuAcceleration: true,
                          positionFixed: true,
                          preventOverflow: true,}"
                                            trigger="click"
                                            popper-class="bid_popover no_profile_popover">
                                        <img slot="reference" class="card_profile"
                                             :src="returnItemProfile(itemData,'curator')">
                                        <div class="popover_wrap"
                                             @click="moveMember(`creator/${itemData.ibc_curator}`)">
                                            <div class="popover_top">
                                                <div class="popover_creator">
                                                    {{ $t('curator') }}
                                                </div>
                                                <div class="popover_nickname">
                                                    <img class="nickname_img" :src="returnMemberImg()">
                                                    <div class="popover_text_list">
                                                        <div class="popover_nickname_list">
                                                            <div class="nick_text">{{ memberData.mb_nick }}</div>
                                                            <div class="pop_curator" v-if="memberData.g_idx=== 2">
                                                                <span>Curator</span></div>
                                                            <div class="pop_curator second_color"
                                                                 v-if="memberData.mb_premium===1">
                                                                <span>Special</span></div>
                                                        </div>
                                                        <div class="popover_nickname2">
                                                            <img class="pn_img"
                                                                 src="@/assets/image/main/heart1.png">
                                                            <div>{{ memberData.likeCnt }}</div>
                                                            <div>ITEM</div>
                                                            <div>{{ memberData.itemCnt }}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="popover_bottom">
                                                <div>
                                                    {{ memberData.mb_introduce }}
                                                </div>
                                            </div>
                                        </div>
                                    </el-popover>
                                </li>
                            </ul>
                        </div>
                        <div class="header_title">
                            <h3 class="title_left">{{ returnCategoryName(itemData) }}</h3>
                            <h3 class="title_right">{{ itemData.i_name }}</h3>
                        </div>
                    </div>
                    <div class="inform_content">

                        <div class="content_top license_content_top">
                            <div class="content_wrap1 content_wrap1_div">
                                <div class="content_wrap1_left">
                                    <h3 class="sub_title left_h3 sub_h3_1">{{ typeName }}</h3>
                                    <div class="detail_wrap left_div left_div_1" v-if="itemData.im_type==='photo'">
                                        <div>{{ Math.floor(size[0]) }}*{{ Math.floor(size[1]) }} px
                                        </div>
                                    </div>
                                </div>
                                <div class="content_wrap1_right">
                                    <span class="amount right_span span_mg_1">  {{ returnCommas(price) }} Mg</span><br/>
                                    <small class="right_small">({{ returnMingle(price) }})</small>
                                </div>

                            </div>
                            <div class="content_wrap2">
                                <h3 class="sub_title sub_h3 sub_h3_1">{{ $t('f_amount') }}</h3>
                                <div class="content_wrap2_right">
                                    <span class="span_mg span_mg_1"> {{ returnCommas(price) }} Mg</span><br/>
                                    <span class="span_w">({{ returnMingle(price) }})</span>
                                </div>
                            </div>
                        </div>
                        <div class="search_item">
                            <el-checkbox v-model="termsCheck" @click="changeTerms">
                                <span class="item_text_span bold"
                                      @click="openModal('terms-modal', 'licenseBuy')">{{ $t('terms_license') }}</span>
                                <span v-html="$t('agree_terms2')"></span>

                            </el-checkbox>
                        </div>
                        <div class="btn_wrap" v-if="checkMemberType()">
                            <button class="cancel" @click="backEvent">{{ $t('cancel') }}</button>
                            <button class="gobid" @click="buyConfirmFunc">{{
                                    $t('buy')
                                }}
                            </button>
                        </div>
                        <div class="btn_wrap" v-else>
                            <button class="cancel cancel_100" @click="backEvent">{{ $t('back_msg') }}</button>
                        </div>
                        <div class="caption_check">
                            <div class="mt10 val_cap" v-if="termsMsgType"><p>{{ $t('caption_check') }}</p></div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
import {Swiper, SwiperSlide} from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'
import util from "@/mixins/util";
import EventBus from "@/utils/event-bus";
import {mapState} from "vuex";
import imageOption from "@/mixins/imageOption";
import dateMixins from "@/mixins/dateMixins"
import imageResizeMixins from "@/mixins/imageResizeMixins";
import AuthMixins from "@/mixins/AuthMixins";
import priceMixins from "@/mixins/priceMixins";
import alertMixins from "@/mixins/alertMixins";

export default {
    name: "ItemDetailLicenseLayout",
    mixins: [imageOption, dateMixins, imageResizeMixins, AuthMixins, priceMixins, alertMixins],
    components: {
        Swiper,
        SwiperSlide,
    },
    inject: [],
    provide() {
        return {}
    },
    props: {
        itemData: {},
    },
    data() {
        return {
            itemCategorys: {},
            swiperOption: {
                slidesPerView: 4,
                spaceBetween: 8,
                grabCursor: true,
                autoWidth: true,
            },
            memberData: {},
            price: '',
            fees: '',
            termsCheck: false,
            termsMsgType: false,
            licenseType: '',
            type: '',
            typeName: '',
            size: [],


        }
    },
    beforeRouterEnter() {

    },
    created() {
        this.licenseType = this.$route.params.type;
        this.initItemData();
    },
    mounted() {
    },
    beforeDestroy() {
    },
    destroyed() {
    },
    computed: {
        ...mapState({
            itemCategoryList: state => state.itemCategory.itemCategoryList,
            UserInfo: state => state.userInfo.UserInfo,
        })
    },
    methods: {
        initItemData() {
            if (!util.isEmpty(this.itemData) && !util.isEmpty(this.itemData.ItemFile) && !util.isEmpty(this.itemData.ItemFile.Detail)) {
                this.thumnailImgs = this.itemData.ItemFile.Detail;
                this.orgItemImg = this.itemData.ItemFile.Item[0].org_url;
                this.mainImg = this.orgItemImg;
                this.thumnailStatus = true;
            } else {
                this.thumnailImgs = [];
                this.mainImg = '';
                this.thumnailStatus = false;
            }
            if (!util.isEmpty(this.itemData)) {
                if(!util.isEmpty(this.itemData.imo_size)){
                    let afterSize = this.itemData.imo_size.split('*');
                    this.imageResize(Number(afterSize[0]), Number(afterSize[1]))
                }
                this.setLicenseData();
            }
        },
        setLicenseData() {
            if (this.itemData.im_type === 'photo') {
                if (this.licenseType === 'small') {
                    this.type = 'Small';

                    this.size[0] = this.smallSize[0];
                    this.size[1] = this.smallSize[1];
                    this.price = this.itemData.il_small_price;
                } else if (this.licenseType === 'medium') {
                    this.type = 'Medium';
                    this.size[0] = this.mediumSize[0];
                    this.size[1] = this.mediumSize[1];
                    this.price = this.itemData.il_medium_price
                } else if (this.licenseType === 'large') {
                    this.type = 'Large';
                    this.size[0] = this.largeSize[0];
                    this.size[1] = this.largeSize[1];
                    this.price = this.itemData.il_large_price
                } else if (this.licenseType === 'original') {
                    let afterSize = this.itemData.imo_size.split('*');
                    this.type = 'Original';
                    this.size[0] = afterSize[0];
                    this.size[1] = afterSize[1];
                    this.price = this.itemData.il_original_price
                }
                this.typeName = this.type;
            } else {
                this.typeName = this.$t('license_sell_amount');
                this.type = 'Original';
                this.price = this.itemData.il_original_price
                console.log(this.itemData.il_original_price);
            }
        },
        openModal(data, type) {
            this.$modal.show(`${data}`, {termsType: type});
        },

        returnItemProfile(data, type) {
            if (!util.isEmpty(data.profile) && !util.isEmpty(data.profile[type])) {
                return `${data.profile[type][0].org_url}?${this.setImageOptions(45, 45, this.returnExtension(data.profile[type][0].org_url))}`;
            }
        },
        checkCurator(data) {
            if (!util.isEmpty(data.profile) && !util.isEmpty(data.profile.curator)) {
                return true
            }
            return false;
        },

        replaceBr(content) {
            if (util.isEmpty(content)) {
                return '';
            }
            return content.replace(/(\n|\r\n)/g, '<br>');
        },
        decodeHtml(html = '') {
            return util.htmlDecode(html);
        },

        returnCategoryName(data) {
            if (data.ic_idx === 2) {
                return this.$t('art')
            } else if (data.ic_idx === 3) {
                return this.$t('picture')
            } else if (data.ic_idx === 4) {
                return this.$t('video')
            } else if (data.ic_idx === 5) {
                return this.$t('music')
            } else if (data.ic_idx === 9) {
                return this.$t('collections')
            } else if (data.ic_idx === 178) {
                return this.$t('prompt')
            }
        },

        changeTerms() {
            this.termsCheck = !this.termsCheck;
        },
        getMemberData(data, type) {
            this.memberData = {}
            if (util.isEmpty(data[type])) {
                return
            }
            let params = {
                no: data[type]
            }
            this.$api.$member.getProfile(params).then(res => res.Data.Result).then(res => {
                if (res.Info.type != 1 || util.isEmpty(res.List)) {
                    this.createAlert({
                        content: this.$t('detect_detail_empty'),
                        hide: () => {
                            this.$router.back();
                        }
                    })
                    return false;
                }
                this.memberData = res.List[0];
                this.$forceUpdate()
            })

        },
        returnMemberImg() {
            if (!util.isEmpty(this.memberData.Files) && !util.isEmpty(this.memberData.Files.Profile)) {
                return `${this.memberData.Files.Profile[0].org_url}?${this.setImageOptions(62, 62, this.returnExtension(this.memberData.Files.Profile[0].org_url)
                )}`
            }
        },
        checkMemberData() {
            return !util.isEmpty(this.memberData)
        },
        moveMember(url) {
            this.$router.push(`/${url}`)
        },
        checkMemberType() {
            if (!util.isEmpty(this.UserInfo) && this.itemData.provider === this.UserInfo.mb_no || this.UserInfo.g_idx === 2) {
                return false
            }
            return true
        },
        buyConfirmFunc() {
            this.createConfirm({
                content: this.$t('buy_confirm'),
                confirmText: this.$t('yes'),
                cancelText: this.$t('cancel'),
                confirm: () => {
                    this.checkValidator();
                },
                cancel: () => {
                    return false;
                },
            });
        },
        checkValidator() {
            if (!this.termsCheck) {
                this.termsMsgType = true;
                return;
            } else {
                this.termsMsgType = false;
            }

            if (!this.isLogin()) {
                this.createConfirm({
                    content: this.$t('not_login_confirm'),
                    confirmText: this.$t('go_login'),
                    confirm: () => {
                        this.$router.push('/auth');
                    }
                })
                return false;
            } else {
                if (this.itemData.IsLicense) {
                    this.createConfirm({
                        content: this.$t('license_resale'),
                        confirmText: this.$t('buy'),
                        confirm: () => {
                            this.buyLicense(this.type, this.size, this.price)
                        },
                        cancel: () => {

                        }
                    })
                } else {
                    this.buyLicense(this.type, this.size, this.price)
                }
            }
        }
        ,
        buyLicense(type, size, price) {
            EventBus.$emit('buyLicense', type, size, price);
        }
        ,
        setData(key, value) {
            this[key] = value;
        }
        ,
        backEvent() {
            this.$router.back();
        }
        ,
        slideChange() {
            this.slideIndex = this.$refs.swiper.$swiper.realIndex;
        }
        ,
        onClick(event) {
            if (util.isEmpty(event.target)) {
                return false;
            }
            let element = event.target;
            let elementSrc = element.currentSrc.split('?');

            if (util.isEmpty(elementSrc[0])) {
                return false;
            }
            this.changeThumnail(elementSrc[0]);
        }
        ,

    },
    watch: {
        "itemData": {
            deep: true,
            handler: function (val, oldVal) {
                this.initItemData();
            }
        },
    },
}
</script>

<style scoped>

</style>
